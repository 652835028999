import * as SentrySvelte from '@sentry/svelte';
import { BrowserTracing } from '@sentry/browser';
import type { HandleClientError } from '@sveltejs/kit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@sentry/integrations';

SentrySvelte.init({
	dsn: PUBLIC_SENTRY_DSN,
	integrations: [new BrowserTracing(), new HttpClient()],
	tracesSampleRate: 1.0,
	sendDefaultPii: true,
	ignoreErrors: ['User rejected the request.', 'TypeError: Failed to fetch']
});

SentrySvelte.setTag('svelteKit', 'browser');

// This will catch errors in load functions from +page.ts files
export const handleError = (({ error, event }: { error: any; event: any }) => {
	const errorId = uuidv4();
	SentrySvelte.captureException(error, { contexts: { sveltekit: { event, errorId } } });

	return {
		message: error.message,
		errorId
	};
}) satisfies HandleClientError;
